import axios from "axios";

/**
    * @function transactionHistoryService
    * @param 
    * @description This function is used to get the list of transaction history
*/
export const transactionHistoryService = async ({perPageItem ='' ,page=''}) => {
    try {
        const result = await axios.get(`user/transaction-history?${perPageItem && "&per_page=" + perPageItem} ${page && "&page=" + page}`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
 * @function transactionReceiptService
 * @param {Object} param - The parameters for the API call.
 * @param {string} param.invoiceNo - The invoice number for the receipt.
 * @description This function is used to download a receipt by invoice number.
 * @returns {Promise<string>} - A promise that resolves with the receipt download URL.
 * @throws {Error} - Throws an error if the API call fails.
 */
export const transactionReceiptService = async ({ invoiceNo = '' }) => {
    if (!invoiceNo) {
        throw new Error("Invoice number is required to fetch the receipt.");
    }
   
    try {
        const response = await axios.get(`user/download-receipt/${invoiceNo}`);
        
        // Assuming the response contains the receipt URL in data.url
        if (response?.data) {
            return response?.data;
        } else {
            throw new Error("Invalid response format or URL not found.");
        }
    } catch (error) {
        console.error("Error in transactionReceiptService:", error.message);
        throw new Error("Failed to download the receipt. Please try again later.");
    }
};
