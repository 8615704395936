import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";

//importing component
import HorizentalCard from "../../common/cards/courseCards/HorizentalCard";
import SiteSlider from "../../common/slider/SiteSlider";

//import styles
import "../../../assets/scss/components/course/liveCourse/liveCourse.scss";
import {
    selectClassTypes,
    selectTodayLiveClasses,
    setTodayLiveClasses,
} from "../../../redux/classSlice";
import { getClassService } from "../../../services/dashboard.service";
import { selectUserDetails } from "../../../redux/userSlice";
import Button from "../../common/button/Button";


const LiveCourse = () => {
    const { slug } = useParams();

    const dispatch = useDispatch();

    const getSlug = useSelector(selectClassTypes)?.find((item) => {
        if (slug) {
            return item?.name?.toLowerCase() === slug;
        } else {
            return item?.name?.toLowerCase() === "onlive";
        }
    });

    const date = moment(new Date()).format("YYYY-MM-DD");
    const userDetails = useSelector(selectUserDetails)?.id;


    const fetchTodayLiveClasses = async () => {
        try {
            const result = await getClassService({
                date:date,
                filter: slug ? slug : '',
                user_id: userDetails
            });
            dispatch(setTodayLiveClasses(result?.data?.classes?.data));

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchTodayLiveClasses();

    }, [userDetails]);

   
    const todayLiveClasses = useSelector(selectTodayLiveClasses)?.map((item) => item);
    


    const getClassTitle = () => {
        switch (slug) {
            case 'onlive':
                return 'Today’s OnLIVE'
            case 'onstage':
                return 'Today’s OnStage'
            case 'onference':
                return 'Today’s OnFerence'
            default:
                return 'Today’s LIVE Courses'
        }
    }


    return (
        <>
            {todayLiveClasses?.length > 0 && (

                <div className="OnliveClassListing-main-container">
               
                    <div className="title-new-flex-onlive flex-wrap mx-2">
                    <h2 className="fw-bold fs-4 mb-1 mx-2">{getClassTitle()}</h2>
                        <Link to={`/classes/Todays-classes`}>
                            <div
                                className=" live-course-button"
                            >
                                <Button name="All Upcoming Courses" btnType="whiteButton"  />
                            </div>
                        </Link>
                    </div>
                    <div>
                        <SiteSlider
                            cardType="horizental"
                            noOfItems={todayLiveClasses?.length}
                        >
                            {todayLiveClasses?.map((item, index) => {
                                return (
                                    <div className="" key={`today-class-${index}`}>
                                        <HorizentalCard cardContent={item} />
                                    </div>
                                );
                            })}
                        </SiteSlider>
                    </div>
                    <div></div>
                </div>
            )}
        </>
    );
};

export default LiveCourse;
