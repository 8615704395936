const Image = ({ src, defaultImage, alt, style, className }) => {
    const handleError = (event) => {
        if (defaultImage && event.target.src !== defaultImage) {
            event.target.src = defaultImage;
        }
    };

    return (
        <img
            className={className}
            src={src || defaultImage} // Use the default image if `src` is undefined or null
            alt={alt}
            style={style}
            onError={handleError}
        />
    );
};

export default Image;
